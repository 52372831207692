// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-cataloage-js": () => import("./../../../src/pages/cataloage.js" /* webpackChunkName: "component---src-pages-cataloage-js" */),
  "component---src-pages-cataloage-open-js": () => import("./../../../src/pages/cataloage-open.js" /* webpackChunkName: "component---src-pages-cataloage-open-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-despre-js": () => import("./../../../src/pages/despre.js" /* webpackChunkName: "component---src-pages-despre-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portofoliu-js": () => import("./../../../src/pages/portofoliu.js" /* webpackChunkName: "component---src-pages-portofoliu-js" */),
  "component---src-templates-portofoliu-page-js": () => import("./../../../src/templates/portofoliu-page.js" /* webpackChunkName: "component---src-templates-portofoliu-page-js" */),
  "component---src-templates-produse-js": () => import("./../../../src/templates/produse.js" /* webpackChunkName: "component---src-templates-produse-js" */),
  "component---src-templates-produse-subcategorii-js": () => import("./../../../src/templates/produse-subcategorii.js" /* webpackChunkName: "component---src-templates-produse-subcategorii-js" */)
}

