module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Maison Design","short_name":"maisondesign","start_url":"/","lang":"ro","description":"Cu o experiență de peste 13 ani în amenajări interioare, Maison Design aduce în prim plan eleganța și rafinamentul Porcelanosa prin elementele care satisfac orice exigență legată de inovație, exclusivism și calitate.","background_color":"#eee","theme_color":"#eee","display":"standalone","icon":"src/images/logo2.png","cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
